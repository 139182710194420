import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { structuredTextPropTypes } from "../../utils/prop-types";
import CustomLink from "../../components/CustomLink";
import style from "./style";
import { motion } from "framer-motion";
import { vFadeIn, vScaleFade, vSlideLeftFade } from "../../utils/animations";

const AboutIntro = ({ slice, classes }) => {
  const { primary } = slice;

  const boldText = primary.boldText && RichText.asText(primary.boldText);
  const primaryCtaButtonText =
    primary.primaryCtaButtonText &&
    RichText.asText(primary.primaryCtaButtonText);
  const secondaryCtaButtonText =
    primary.secondaryCtaButtonText &&
    RichText.asText(primary.secondaryCtaButtonText);

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <Container maxWidth="lg" className={classes.intro}>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div className={classes.highlightedText}>
            <motion.div
              variants={vFadeIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              {RichText.render(primary.highlightedText)}
            </motion.div>
          </div>
          <motion.div
            className="description"
            variants={vSlideLeftFade}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={viewport}
          >
            {RichText.render(primary.description)}
          </motion.div>
          {boldText && (
            <motion.div
              variants={vFadeIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <Typography variant="h4" className="boldText">
                {boldText}
              </Typography>
            </motion.div>
          )}
          {(primaryCtaButtonText || secondaryCtaButtonText) && (
            <div className={classes.aboutButton}>
              {primaryCtaButtonText && (
                <CustomLink link={primary.primaryCtaButtonLink}>
                  <motion.div
                    variants={vScaleFade}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={viewport}
                  >
                    <Button variant="contained" color="primary" className="cta">
                      {primaryCtaButtonText}
                    </Button>
                  </motion.div>
                </CustomLink>
              )}
              {secondaryCtaButtonText && (
                <CustomLink link={primary.secondaryCtaButtonLink}>
                  <motion.div
                    variants={vScaleFade}
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={viewport}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className="cta"
                    >
                      {secondaryCtaButtonText}
                    </Button>
                  </motion.div>
                </CustomLink>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

AboutIntro.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      highlightedText: structuredTextPropTypes,
      description: structuredTextPropTypes,
      boldText: structuredTextPropTypes,
      primaryCtaButtonText: structuredTextPropTypes,
      primaryCtaButtonLink: PropTypes.objectOf(PropTypes.any),
      secondaryCtaButtonText: structuredTextPropTypes,
      secondaryCtaButtonLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(AboutIntro);
